import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BoxWhite from 'components/BoxWhite';
import { Row, Col } from 'reactstrap';
import Jumbotron, { Lead } from 'components/Jumbotron';
import Categories from 'components/Categories';
import Link from 'components/Link';
import Layout from 'components/Layout';
import Search from 'components/Search';
import prismic from '../prismic';
import { fetchHome } from 'actions/prismic';
import { fetchCategories } from 'actions/categories';
import i18n from 'i18n';

// @todo get mission, caption, meta from prismic (new page in there)

class ProductCheckerHome extends Component {
  static async getInitialProps({ store, prismicRef, lang }) {
    store.dispatch(fetchCategories({ lang, prismicRef }));
    return store.dispatch(fetchHome({ lang, prismicRef }));
  }

  render() {
    const { home, categories, ...rest } = this.props;

    if (!categories) return <Layout {...rest} />;

    const { stats, ...other } = prismic.home(home);

    return (
      <Layout {...other} {...rest} dept="product-checker" hasCover>
        <Jumbotron
          className="text-center text-white mx-3 rounded"
          bg="/static/images/supermarket_banner_dark.jpg">
          <h1>{i18n.t('PRODUCT_CHECKER_MISSION')}</h1>
          <Lead className="my-3">{i18n.t('PRODUCT_CHECKER_CAPTION')}</Lead>
          <div className="d-flex justify-content-center">
            <div className="text-left my-4">
              <Search focus />
            </div>
          </div>
        </Jumbotron>

        <div className="container my-5">
          <h1>
            <strong>{i18n.t('BROWSE_CATEGORIES')}</strong>
          </h1>
          <BoxWhite
            className="rounded my-4 my-sm-5 col-12 col-sm-12 col-md-12 col-lg-9 bg-ship-light"
            style={{ zIndex: 999 }}>
            <Categories.Browse
              items={categories.items}
              renderer={CategoryLink}
            />
          </BoxWhite>

          <BoxWhite
            className="rounded my-4 my-sm-5 bg-corn col-12 col-sm-12 col-md-12 offset-lg-3 col-lg-9 overlap-y-lg"
            iff={stats.length > 0}>
            <Row>
              {stats.map((stat, index) => (
                <Col key={index} xs={12} sm={12} md={12 / stats.length}>
                  <Stat>
                    <div className="py-4">
                      {stat.icon_name && (
                        <i className={`fa fa-${stat.icon_name} fa-3x`} />
                      )}
                    </div>
                    <h3>{stat.count}</h3>
                    <div>{stat.name}</div>
                  </Stat>
                </Col>
              ))}
            </Row>
          </BoxWhite>
        </div>
      </Layout>
    );
  }
}

ProductCheckerHome.propTypes = {
  home: PropTypes.object,
  categories: PropTypes.object
};

export default connect(({ prismic, categories }) => ({
  home: prismic.home,
  categories
}))(ProductCheckerHome);

const Stat = styled.div.attrs({
  className: 'py-3 py-sm-4 py-md-5 px-3 px-sm-5 text-center'
})`
  h3 {
    color: white;
  }
  div {
    font-size: 0.9333rem;
    max-width: 200px;
    margin: 0 auto;
  }
`; // 0.9333rem = 14px

const CategoryLink = styled(Link)`
  transition: background 0.2s;
  font-weight: 100;
  color: #eee;

  &:hover {
    background: #444;
    color: #fff;
    text-decoration: none;
  }
`;
