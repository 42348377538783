import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row } from 'reactstrap';
import Image from 'components/Image';
import Link from 'components/Link';
import slugify from 'lib/slugify';
import { toQS } from 'lib/query-params';

export default {
  Sub,
  Browse
};

function Browse({ items, renderer }) {
  const LinkComponent = renderer || CategoryLink;
  return (
    <Row>
      {items.map((category, index) => (
        <LinkComponent
          className="col-6 col-sm-6 col-md-4 col-lg-3 text-center"
          key={index}
          route={`/categories/${slugify(category.id, category.name)}`}>
          <div className="p-0 p-sm-3 my-3">
            <Image
              src={
                (category.image_url && category.image_url.original) ||
                '/static/images/default-category.png'
              }
              className="img-fluid mx-auto px-3 px-sm-5 px-lg-3"
              alt={category.name}
              style={{ maxHeight: 90 }}
            />
            <div>{category.name}</div>
          </div>
        </LinkComponent>
      ))}
    </Row>
  );
}

Browse.propTypes = {
  items: PropTypes.array.isRequired,
  renderer: PropTypes.object
};

Browse.defaultProps = {
  items: []
};

function Sub({ category, query }) {
  if (!category) return null;
  return (
    <ul className="nav flex-column">
      {category.children.map((cat, index) => (
        <li key={index} className="nav-item">
          <Link
            className="nav-link"
            route={`/categories/${slugify(cat.id, cat.name)}?${toQS(query)}`}>
            {cat.name}
          </Link>
        </li>
      ))}
    </ul>
  );
}

Sub.propTypes = {
  category: PropTypes.object,
  query: PropTypes.object
};

Sub.defaultProps = {
  category: {},
  query: {}
};

const CategoryLink = styled(Link)`
  transition: background 0.2s;
  &:hover {
    text-decoration: none;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.lightGrey};
  }
`;
