import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { Jumbotron } from 'reactstrap';
import media from 'components/Media';
import styled from 'styled-components';

const JumbotronStyled = styled(Jumbotron)`
  background-repeat: no-repeat !important;
  background-position: center bottom !important;
  background-size: cover !important;
  border-radius: 4px !important;
  min-height: 390px;
  ${media.up.tablet`
    background: ${(props) => background(props.bg)};
  `};
  ${media.down.tablet`
    background: ${(props) => background(props['bg-tablet'])};
  `};
`;

JumbotronStyled.propTypes = {
  bg: PropTypes.string,
  'bg-tablet': PropTypes.string
};

export default JumbotronStyled;

const background = (bg) =>
  bg
    ? `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.05)), url(${bg})`
    : 'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.05))';

export const Title = styled.h2`
  font-size: 4rem;
  line-height: 1.2;
  max-width: 900px;
`;

export const Lead = styled.div.attrs({
  className: 'font-weight-light'
})`
  margin: 0 auto;
  max-width: 900px;
  line-height: 1.5;
  font-size: 1.8rem;
  ${media.down.tablet`
    font-size: 1.6rem;
  `};
`;
